<template>
  <div class="container_margin">
    <b-modal id="complete-profile" centered hide-footer hide-header>
      <h5><b>{{ "Complete Your Profile" }}</b></h5>

      <p class="text-danger font-14">
        {{
          "Kindly ensure all account details are completed to unlock access to the Legal Writer features."
        }}
      </p>

      <b>{{ "Law Firm Name" }}</b>
      <b-form-input
        class="mt-2"
        v-model="extra_info.company_name"
        name="law firm name"
        placeholder="Company Name"
        v-validate="'required'"
        autocorrect="off"
        autocomplete="off"
      ></b-form-input>
      <span
        class="text-danger text-sm font-14"
        v-show="errors.has('law firm name')"
      >
        {{ errors.first("law firm name") }}
      </span>

      <div class="mt-3">
        <b>{{ "Firm Address" }}</b>
      </div>
      <b-form-input
        class="mt-2"
        v-model="extra_info.firm_address_1"
        placeholder="Address Line 1"
        name="address line 1"
        v-validate="'required'"
        autocorrect="off"
        autocomplete="off"
      ></b-form-input>
      <b-form-input
        class="input-address mt-2"
        v-model="extra_info.firm_address_2"
        placeholder="Address Line 2"
        name="address line 2"
        autocorrect="off"
        autocomplete="off"
      ></b-form-input>
      <b-form-input
        class="input-address mt-2"
        v-model="extra_info.firm_address_3"
        placeholder="Address Line 3"
        autocorrect="off"
        autocomplete="off"
      ></b-form-input>
      <span
        class="text-danger text-sm font-14"
        v-show="errors.has('address line 1')"
      >
        {{ errors.first("address line 1") }}
      </span>

      <div class="mt-3">
        <b>{{ "Law Firm Contact Number" }}</b>
      </div>
      <b-form-input
        class="mt-2"
        type="text"
        v-model="extra_info.contact_number"
        placeholder="Contact Number"
        name="contact number"
        v-validate="'required'"
        autocorrect="off"
        autocomplete="off"
      ></b-form-input>
      <span
        class="text-danger text-sm font-14"
        v-show="errors.has('contact number')"
      >
        {{ errors.first("contact number") }}
      </span>

      <button
        class="btn btn-primary float-right mt-4"
        @click="saveChanges()"
      >
        {{ "Update Profile" }}
      </button>
    </b-modal>

    <template v-if="permissions.includes('nexlaw.letter-of-demand')">
      <!-- start of History button -->
      <div style="height: .5rem;">
        <b-button
          @click="openHistory('LOD')"
          class="float-right btn-primary"
        >
          <b-icon
            icon="clock-history"
            aria-hidden="true"
            class="mx-1"
          />
          LOD and Agreement History
        </b-button>
      </div>
      <!-- end of History button -->

      <!-- start of Custom Agreement Gen. section -->
      <div class="card shadow p-4 my-5">
        <h3 class="mb-2"><b>{{ "Custom Agreement Generator" }}</b></h3>
        <span class="mb-2">
          Provide a concise prompt, limited to 300 words to effortlessly
          generate a customized agreement that caters to specific needs
          and requirements not covered by the predefined agreement types
          listed below.
        </span>

        <div id="customAgreementGenerator">
          <b-form-textarea
            id="exampleTextarea"
            v-model="prompt"
            placeholder="Generate Custom Agreement..."
            rows="4"
            autocorrect="off"
            autocomplete="off"
            v-validate="'required'"
            type="text"
            name="Additional Information"
          ></b-form-textarea>

          <div
            style="margin-top: -2.5rem"
            class="float-right mx-2"
          >
            <b-icon
              @click="nextStep"
              icon="arrow-right-circle"
              font-scale="2"
              class="rounded-circle bg-primary"
              variant="light"
            />
          </div>
        </div>
      </div>
      <!-- end of Custom Agreement Gen. section -->

      <!-- start of Category sections -->
      <div v-for="(section, index) in sections" :key="index">
        <h3 class="mt-4"><b>{{ section.title }}</b></h3>
        <p class="mb-2">{{ section.desc }}</p>

        <div class="row mb-4">
          <div
            class="col-12 col-sm-6 col-xl-4 mb-4"
            v-for="(cat, idx) in section.sub"
            :key="idx"
          >
            <div
              class="card agreement-card shadow text-center p-2 m-3"
              @click="chooseCategory(section.category, cat.title)"
            >
              <span class="icon-darkblue material-symbols-outlined mx-auto">
                {{ cat.icon }}
              </span>
              <b class="mb-1" v-html="cat.title"></b>
              <p v-html="cat.desc"></p>
            </div>
          </div>
        </div>
      </div>
      <!-- end of Category sections -->
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import Auth from "@/store/Auth.js";
import LegalWriter from "@/store/LegalWriter.js";

export default {
  components: {},
  data() {
    return {
      prompt: "",
      extra_info: {
        contact_number: "",
        firm_address_1: "",
        firm_address_2: "",
        firm_address_3: "",
        company_name: JSON.parse(localStorage.userInfo).extra_info?.company_name ?? "",
      },
      permissions: localStorage.permissions,
      info: JSON.parse(localStorage.userInfo),

      // categories
      sections: [
        {
          title: "Letter of Demand (LOD)",
          desc: "Compose a brief Letter of Demand (LOD) addressing specific issues, outlining necessary corrective actions and setting a compliance deadline. Emphasize potential consequences and urge prompt resolution to avoid legal action. The tone is assertive yet cooperative.",
          category: null,
          sub: [
            {
              icon: "payment",
              title: "Outstanding Payment",
              desc: "Construct a demand letter for debt payment",
            },
            {
              icon: "maps_home_work",
              title: "Property Damage Claim",
              desc: "Construct a demand letter for a damaged property",
            },
            {
              icon: "shield",
              title: "Insurance Claim",
              desc: "Construct a demand letter for an Insurance Claim",
            },
          ],
        },
        {
          title: "Partnership Agreement",
          desc: "Construct a letter that outlines the terms and conditions of a collaborative arrangement between individuals or entities. A comprehensive guide that defines the roles, responsibilities, rights, expectations and obligations of each partner within the partnership.",
          category: "Partnership",
          sub: [
            {
              icon: "storefront",
              title: "Small Businesses",
              desc: "Local Coffee Shop, Home Cleaning Shop, Boutique Clothing Shop",
            },
            {
              icon: "lightbulb",
              title: "Creative Partnerships",
              desc: "Music collaboration, Podcast, Writers, Movie Making",
            },
            {
              icon: "home_work",
              title: "Real Estate Ventures",
              desc: "Residential Real Estate, Commercial Real Estate, Green Real Estate Ventures",
            },
            {
              icon: "important_devices",
              title: "Technology Ventures",
              desc: "Websites, Mobile applications, E-commerce platforms, E-learning and Online Education",
            },
            {
              icon: "diversity_3",
              title: "Join Ventures",
              desc: "Research and Development (R&D) Joint Venture, Marketing Joint Venture, Consortium Joint Venture",
            },
          ],
        },
        {
          title: "Sales and Purchase Agreement",
          desc: "Construct a contract between a buyer and a seller that outlines the terms and conditions of a sale of goods, products, or services. This document serves as a formal agreement that both parties agree to before finalizing the transaction. This serves as a safeguard for both the buyer and the seller, ensuring that both parties understand their rights and obligations.",
          category: "Sales and Purchase",
          sub: [
            {
              icon: "real_estate_agent",
              title: "Real Estate Purchase Businesses",
              desc: "Buying or selling real estate property",
            },
            {
              icon: "finance_mode",
              title: "Stock Purchase Agreement",
              desc: "Purchase of a company's outstanding shares",
            },
            {
              icon: "credit_score",
              title: "Subscription Agreement",
              desc: "Subscription-based services or products that may involve subscription term, payment frequency, renewal conditions, and cancellation policies",
            },
            {
              icon: "public",
              title: "International",
              desc: "Cross-border transactions that may involve involves shipping, customs, taxes, and compliance with international laws",
            },
            {
              icon: "domain",
              title: "Asset Purchase",
              desc: "Formalize the purchase and sale of specific assets between parties, including terms, conditions, and transfer details",
            },
            {
              icon: "business_center",
              title: "Business Purchase",
              desc: "Establish the terms and conditions for the acquisition and sale of an entire business entity",
            },
          ],
        },
        {
          title: "Non-Disclosure Agreement",
          desc: "Construct a contract between parties that outlines the terms and conditions under which certain confidential information will be shared while restricting its disclosure to third parties. This is commonly used to protect sensitive information, trade secrets, proprietary knowledge, and other confidential details from being disclosed or used for unauthorized purposes.",
          category: "Non-Disclosure Agreement",
          sub: [
            {
              icon: "badge",
              title: "Employee",
              desc: "Protect the employer's proprietary information and prevent the employee from sharing it",
            },
            {
              icon: "local_shipping",
              title: "Vendor/Supplier",
              desc: "Protect the recipient company's sensitive business information that it may need to share with vendors or suppliers",
            },
            {
              icon: "paid",
              title: "Investor",
              desc: "Protect entrepreneur's business information that it may need to share with potential investors",
            },
            {
              icon: "record_voice_over",
              title: "Consultant",
              desc: "Protect individual's confidential information that it may need to share with consultant",
            },
            {
              icon: "handshake",
              title: "Non-Compete",
              desc: "Protect the interests of one party by preventing the other party from engaging in competitive activities that could harm the first party's business or disclose sensitive information",
            },
            {
              icon: "group",
              title: "Unilateral",
              desc: "Protect sensitive information with one party obligated to keep it confidential",
            },
            {
              icon: "diversity_2",
              title: "Mutual",
              desc: "Both parties agree to keep confidential information secure and undisclosed",
            },
            {
              icon: "partner_exchange",
              title: "Partnership",
              desc: "Ensure confidentiality between partners in a collaborative business arrangement",
            },
            {
              icon: "reduce_capacity",
              title: "Board Members",
              desc: "Safeguard proprietary information shared among board members of an organization",
            },
          ],
        },
        {
          title: "Creative Media Agreement",
          desc: "Construct the terms and conditions for creating, licensing, and distributing media content, ensuring clarity and understanding of rights and obligations between a creative professional or company and a client. These agreements include creative media content such as videos, films, photographs, music, or artwork.",
          category: "Creative Media",
          sub: [
            {
              icon: "deployed_code_history",
              title: "Option Agreement",
              desc: "Establish rights and terms for the future acquisition of creative media content",
            },
            {
              icon: "movie",
              title: "Development Agreement",
              desc: "Define terms for the creation and development of creative media content",
            },
            {
              icon: "video_file",
              title: "Production Agreement",
              desc: "Establish terms for the production and execution of creative media projects",
            },
            {
              icon: "subtitles",
              title: "License Agreement",
              desc: "Define the terms and conditions for licensing creative media content",
            },
            {
              icon: "theater_comedy",
              title: "Talent Agreement",
              desc: "Establish terms and obligations between talent and creative media professionals or companies",
            },
            {
              icon: "connect_without_contact",
              title: "Syndication Agreement",
              desc: "Determine terms for the syndication and distribution of creative media content",
            },
            {
              icon: "shop",
              title: "Distribution Agreement",
              desc: "Determine terms for the distribution and dissemination of creative media content",
            },
            {
              icon: "communication",
              title: "Pro-Producer Distributor Agreement",
              desc: "Establish terms between a professional producer and a distributor for content distribution",
            },
            {
              icon: "camera_roll",
              title: "Film Production Agreement",
              desc: "Define terms for the production of a film",
            },
            {
              icon: "brush",
              title: "Loan of Art Agreement",
              desc: "Outline terms for the temporary loan of artwork",
            },
            {
              icon: "movie_edit",
              title: "Screenplay Writer Commission Agreement",
              desc: "Establish terms and compensation for a screenplay writer's work",
            },
          ],
        },
        {
          title: "Other Agreements",
          desc: "",
          category: "Others",
          sub: [
            {
              icon: "emoji_objects",
              title: "Intellectual Property Assignment Agreement",
              desc: "Transfer ownership rights of intellectual property from one party to another",
            },
            {
              icon: "person_play",
              title: "Distribution Agreement",
              desc: "Establish terms for distributing products or services between parties",
            },
            {
              icon: "store",
              title: "Franchise Agreement",
              desc: "Define the rights and obligations of a franchisor and franchisee in a business partnership",
            },
            {
              icon: "precision_manufacturing",
              title: "Manufacturing Agreement",
              desc: "Outline terms for manufacturing products on behalf of a company",
            },
            {
              icon: "deployed_code_account",
              title: "Consignment Agreement",
              desc: "Establish terms for consigning goods to a consignee for sale",
            },
            {
              icon: "engineering",
              title: "Independent Constructor Agreement",
              desc: "Define the terms of engagement between an independent contractor and a client",
            },
            {
              icon: "more_time",
              title: "Construction Contract Acceleration Agreement",
              desc: "Modify construction project timelines and deadlines",
            },
            {
              icon: "hail",
              title: "Sales Agency Agreement",
              desc: "Establish terms for a sales agency to represent and sell products or services",
            },
            {
              icon: "web",
              title: "Web Design Agreement",
              desc: "Define the scope, terms, and deliverables for a web design project",
            },
            {
              icon: "payments",
              title: "Payment Agreement",
              desc: "Provide assurance of payment for goods or services rendered",
            },
            {
              icon: "local_atm",
              title: "Sponsorship Agreement",
              desc: "Outline terms and obligations for sponsors and sponsored entities",
            },
          ],
        },
      ],
    };
  },

  methods: {
    openHistory(data) {
      this.$router.push({
        name: "LegalWriterHistory",
        query: {
          category: data,
        },
      });
    },
    extraInfoCheck() {
      const extra_info = JSON.parse(localStorage.userInfo).extra_info;
      return !extra_info.company_name ||
            !extra_info.contact_number ||
            !extra_info.address ||
            !extra_info.address.length;
    },
    chooseCategory(data, subData) {
      if (this.extraInfoCheck()) {
        this.$bvModal.show('complete-profile');
        return;
      }
      const route = {
        name: "LegalWriterInputs",
        params: { step: "general_details" },
        query: { category: subData },
      }
      if (data) {
        route.params.step = "agreement_general_details";
        route.query = {
          category: data,
          sub_category: subData
        };
      }
      this.$router.push(route);
    },

    nextStep() {
      this.prompt = this.prompt.trim();
      if (this.prompt.length === 0) {
        this.$toast.error("Please enter a prompt to generate custom agreement");
      } else if (this.prompt.split(/\s+/).length > 300) {
        this.$toast.error("Please limit your prompt to a maximum of 300 words only");
      } else {
        const obj = {
          info: {
            prompt: this.prompt,
          },
          compose_type: "custom",
          letter_type: "custom",
          location_id: 1,
          language: "en",
        };

        LegalWriter.CreateLOD(obj)
          .then((response) => {
            this.$router.push({
              name: "LegalWriterEdit",
              params: {
                id: response.data.data.id,
              },
              query: {
                category: "Custom Agreement",
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    saveChanges() {
      switch("") {
        case this.extra_info.company_name:
        case this.extra_info.firm_address_1:
        case this.extra_info.contact_number:
          this.$toast.error("Please fill in all fields");
          return;
      }
      switch(null) {
        case this.extra_info.company_name:
        case this.extra_info.firm_address_1:
        case this.extra_info.contact_number:
          this.$toast.error("Please fill in all fields");
          return;
      }

      this.extra_info.firm_address_2 = this.extra_info.firm_address_2 ?? "";
      this.extra_info.firm_address_3 = this.extra_info.firm_address_3 ?? "";

      Auth.updateProfile({
        name: JSON.parse(localStorage.userInfo).name,
        email: JSON.parse(localStorage.userInfo).email,
        extra_info: {
          contact_number: this.extra_info.contact_number,
          address: [
            this.extra_info.firm_address_1,
            this.extra_info.firm_address_2,
            this.extra_info.firm_address_3,
          ].join("\n"),
          company_name: this.extra_info.company_name,
        },
      })
        .then((response) => {
          this.$bvModal.hide("complete-profile");
          this.$toast.success(response.data.message);

          Auth.getMe().then((res) => {
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  created() {
    localStorage.removeItem("lod_first_data");
    const extraInfo = JSON.parse(localStorage.userInfo).extra_info;
    if (extraInfo && extraInfo.length != 0) { // wrong method for object?
      var addressParts = extraInfo.address;
      if (addressParts !== null) {
        addressParts = extraInfo.address?.split("\n") ?? ['','',''];
        this.extra_info.firm_address_1 = addressParts[0];
        this.extra_info.firm_address_2 = addressParts[1];
        this.extra_info.firm_address_3 = addressParts[2];
      }

      this.extra_info.contact_number = extraInfo.contact_number;

      this.extra_info.company_name = extraInfo.company_name;
    }
  },
};
</script>
<style scoped>
.container_margin {
  padding: 2rem;
}

.icon-darkblue {
  font-size: 2rem;
  width: 1.6em;
  text-align: center;
  line-height: 1.6em;
  background: #0056a2;
  color: #fff;
  border-radius: 0.8em;
  margin: 1rem;
  user-select: none;
}

.agreement-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  user-select: none;
}
</style>
